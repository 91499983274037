import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const PaginationStyle = styled.div`
    margin-top: 30px;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .pagination-arrow {
        width: 40px;
        height: 40px;
        margin: 10px;
        background: var(--white-color-2);
        border: solid 1px var(--gray-color-3);
        border-radius: 5px;
        color: var(--gray-color-3);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: var(--transition-timing);
        cursor: pointer;
        &:hover {
            background: var(--white-color-1);
        }
    }
`

function Pagination({ currentPage, pages }) {
    const nextPage = currentPage + 1
    const prevPage = currentPage - 1
    return (
        <div>
            <PaginationStyle>
                {prevPage >= 1 && (
                    <Link to={`/news/${prevPage}`}>
                    <span className='pagination-arrow'>
                            ←
                    </span>
                    </Link>
                ) }
                {nextPage <= pages && (
                        <Link to={`/news/${nextPage}`}>
                    <span className='pagination-arrow'>
                            →
                    </span>
                        </Link>
                ) }
            </PaginationStyle>
        </div>
    )
}

export default Pagination
