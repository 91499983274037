import React from 'react'
import styled from 'styled-components'

const NewsComponentStyle = styled.div`
    background: #fafafa;
    border-radius: 2px;
    padding: 25px;
    font-family: var(--gothic);
    font-weight: 300;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .news-content {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        .news-title {
            font-size: 16px;
            font-weight: 400;
            color: #5a5a5a;
        }
        .news-date {
            font-size: 14px;
            color: #777;
            padding: 10px 0;
        }
        .news-description {
            p {
                font-size: 14px;
                color: #5a5a5a;
                line-height: 1.4;
            }
            img {
                padding: 15px;
                padding-left: 0px;
            }
        }
    }
    @media (max-width: 540px) {
        .news-content {
            margin: 0;
        }
    }
`

function NewsComponent({title, description, date}) {
    return (
        <NewsComponentStyle>
            <div className='news-content'>
                <div className='news-title'>{title}</div>
                <div className='news-date'>{date}</div>
                <div className='news-description'>{description}</div>
            </div>
        </NewsComponentStyle>
    )
}

export default NewsComponent
