import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout/layout'
import styled from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Title from '../components/common/Title'
import PageTop from '../components/common/PageTop'
import PageComponent from '../components/common/PageComponent'
import NewsComponent from '../components/news/NewsComponent'
import Pagination from '../components/common/Pagination'

const pageComponentStyle = {
    background: 'var(--white-color-2)',
    padding: 0
}
const containerStyle = {
    maxWidth: '1000px',
    margin: '0 auto',
}

const NewsStyle = styled.ul`
    min-height: 500px;
    max-width: 1000px;
    font-family: var(--gothic);
    font-weight: 300;
    color: var(--gray-color-2);
    font-size: 16px;
    .news-component {
        margin-top: 20px;
    }
    margin-bottom: 60px;
`

export const pageQuery = graphql`
    query News($limit: Int! = 10, $skip: Int! = 0) {
        allContentfulNews( 
            sort: { fields: date, order: DESC } 
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    title 
                    slug 
                    date 
                    content { 
                        raw 
                        references {
                            title
                            contentful_id
                            fixed(width: 400) {
                                width
                                height
                                src
                        }
                        }
                    }
                }
            }
        }
    }
`;


const NewsPage = ({ pageContext, data }) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [width, setWidth] = useState();
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        if (!isLoaded) {
            setIsLoaded(true)
            handleResize();
        }
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
    }, []);
    const { edges } = data.allContentfulNews
    let options = {}
    if (edges) {
        edges.forEach((edge, index) => {
            const { references } = edge.node.content
            options[index] = {
                renderNode: {
                    'embedded-asset-block': (node) => {
                        const referenceImage = references.filter(reference => 
                            reference.contentful_id === node.data.target.sys.id
                        )[0]
                        return <img src={referenceImage.fixed.src} alt={referenceImage.title} style={{width: '100%', maxWidth: '400px'}}/>
                    }
                }
            }
        })
    }
    return (
        <Layout pageTitle="お知らせ">
            <PageTop title='NEWS' />
            <PageComponent styles={pageComponentStyle} containerStyles={containerStyle}>
                <Title title='お知らせ' subtitle='シュプリームテクノロジーの最新情報'/>
                <NewsStyle>
                    {edges.map((edge, index) => {
                        if (!edge.node?.slug) { 
                            return ''
                        }
                        return (
                            <div className='news-component' key={index}>
                                <NewsComponent 
                                    title={edge.node.title}
                                    description={documentToReactComponents(JSON.parse(edge.node.content.raw), options[index])}
                                    date={edge.node.date}
                                />
                            </div>
                        )
                    })}
                    <Pagination 
                        currentPage={pageContext.currentPage} 
                        pages={pageContext.pages} 
                    />
                </NewsStyle>
            </PageComponent>
        </Layout>
    )
}

export default NewsPage
